import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog, faEnvelope, faFile, faHeart, faStar, faQuestion, faPowerOff, faBell } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sidebar.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";

const Sidebar = () => {
  const navigate = useNavigate();


  useEffect(() => {
    let user = localStorage.getItem("role");
    if (!user) {
      navigate("/Singin");
    }
  }, []);

  const userNotAvail = () => {
    console.log("ab====");
    localStorage.clear();
  }
  return (

    <div className="user-sidebar">
      <div className="sidebar-header">
        <h4>Hi, {localStorage.getItem("firstname")}</h4>
        {/* <p style={{ fontSize: '12px' }}> <span style={{ fontSize: '14px', fontWeight: 'bold' }} >Account Number:</span>123456789</p> */}
      </div>

    

      <p className="user-menu-item">
        <FontAwesomeIcon icon={faUser} />
        <Link to="/UserDashboad">
          <span>
            <a style={{ textDecoration: 'none', color: 'black' }} href='#'>Your Profile</a>
          </span>
        </Link>

      </p>


      <p className="user-menu-item">
        <FontAwesomeIcon icon={faCog} />  
        <Link to="/dashboardPurchases">
          <span>
            <a style={{ textDecoration: 'none', color: 'black' }} href='#'>Your Sales</a>
          </span>
        </Link>
      </p>

      {/* <p className="user-menu-item">
        <FontAwesomeIcon icon={faEnvelope} />
        <span>
          <a style={{ textDecoration: 'none', color: 'black' }} href='#'>Favourite</a>
        </span> 
        <hr className='sidebar-divider'></hr>
      </p> */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <p className="user-menu-item">
        <span>
          <a style={{ textDecoration: 'none', color: 'black' }} href='#'></a>
        </span>


      </p>
      <hr className="sidebar-divider" ></hr>
 
     
       
     

      <span onClick={userNotAvail}>
        <p className="user-menu-item" >
          &nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faBell} />
          <span>Logout!</span>
        </p>
      </span>

    </div >

  );
};

export default Sidebar;
