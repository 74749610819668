import React, { useEffect, useState } from "react";
import "./Banar.css"
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";

import {Paper, Grid, Card, CardContent} from '@mui/material'

const Banar = () => {


    const [searchData, setSearchData] = useState({});
    const [SubCatagoryData, setSubCatagoryData] = useState({});


    useEffect(()=>{
        getAllSubCatagories();
    },[]);

    const AllGetSearchData = (data) => {
        axios.get(`${Env.server}/api/product/getSingleProduct/${data}`)
            .then((res) => {
                // console.log("abc===>>>", res.data.message);
                console.log("abc===>>>", res.data.products);
                setSearchData(res.data.products)
            })
            .catch((err) => {
                console.log("err===>>>", err);
            })
    }

    const getAllSubCatagories = ()=>{
        axios.get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
    .then((res)=>{
        console.log("res===>>>", res.data.subcatagories);
        setSubCatagoryData(res.data.subcatagories)
    })
    .catch((err) => {
        console.log("err===>>>", err);
    })
}

    return (
        <>
        
            <Navbar/><br /><br />
            <section className="">
                <div className="row justify-content-center">
                   
                    <div className="col-md-12 text-center pt-2 pl-0 pr-0">
                        <img src="../image/banarimage/khuram.png" width="80%" alt="" className="mt-4 imgslider" />
                        <div className="main mt-5 pb-4">
                            <div className="search-box pt-3 mb-3">
                                <div className="dropdown"><br /><br />
                                    <input
                                        className="search-input rounded-pill text-center p-2"
                                        type="text"
                                        id="dropdownMenuButton"
                                        onChange={(e) => AllGetSearchData(e.target.value)}
                                        // data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        placeholder="What do you want to sell?"
                                    /> 
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button
                                        className="search-btn p-2"
                                        style={{ marginTop: "50px", marginLeft: "310px" }}
                                    >
                                        <i className="bi bi-search fs-5"></i>
                                    </button>
                                </div>
                            </div> 
                            <div>
                                <h5 className="text-white my-5">Or find your item from selected categories below</h5>
                                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    {Array.isArray(SubCatagoryData) && SubCatagoryData.slice(0, 9).map((data, index) => (
                                        <Grid key={index} item lg={2} md={3} sm={4} xs={6} sx={{ margin: "0.5rem" }}>
                                            <Link to={`/Specification/${data.subcatagory}/${data._id}`} style={{ textDecoration: 'none', background: '#0000' }}>
                                                <Card variant="outlined" sx={{width: '75%', height: '100%', borderRadius: 4, backgroundColor: '#0000' }} >
                                                    <CardContent sx={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <img style={{height: '100px', marginBottom: '0.5rem' }} src={getAsset(data.imageSubCatagory[0])} className="img-fluid bordered-image border-0 w-100 bg-white" alt="" />
                                                        <h5 className="smatcard"><b>{data.subcatagory}</b></h5>
                                                    </CardContent>
                                                </Card>
                                            </Link>
                                        </Grid>
                                    ))}
                                </Grid>                               
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Banar;


