import React , {useState, useEffect} from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import './Order.css';
import { useParams } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Order = () => {  
  let navigate = useNavigate();
  const [ClickData, setClickData] = useState([]);
  const [productData, setProductData] = useState([]); // State hook to hold product data from localStorage
  const [TotalPrice, setTotalPrice] = useState(0); // State hook to hold product data from localStorage
  const [Message, setMessage] = useState(""); // State hook to hold product data from localStorage
  


  useEffect(() => {
    function incrementValue(e) {
      e.preventDefault();
      var fieldName = e.target.getAttribute('data-field');
      var parent = e.target.closest('div');
      var inputField = parent.querySelector('input[name="' + fieldName + '"]');
      var currentVal = parseInt(inputField.value, 10);
      
      if (!isNaN(currentVal)) {
        inputField.value = currentVal + 1;
      } else {
        inputField.value = 0;
      }
    }
 
    function decrementValue(e) {
      e.preventDefault();
      var fieldName = e.target.getAttribute('data-field');
      var parent = e.target.closest('div');
      var inputField = parent.querySelector('input[name="' + fieldName + '"]');
      var currentVal = parseInt(inputField.value, 10);

      if (!isNaN(currentVal) && currentVal > 0) {
        inputField.value = currentVal - 1;
      } else {
        inputField.value = 0;
      }
    }

    document.querySelectorAll('.input-group').forEach(function (group) {
      group.addEventListener('click', function (e) {
        if (e.target.classList.contains('button-plus')) {
          incrementValue(e);
        } else if (e.target.classList.contains('button-minus')) {
          decrementValue(e);
        }
      });
    }); 
  }, [])

  const removeItem = (id) => {
    const updatedProductData = productData.filter(item => item.data._id !== id);
    setProductData(updatedProductData);
    localStorage.setItem("productData", JSON.stringify(updatedProductData));
  };


  React.useEffect(() => {
    getDataByIDLocalStorage();
  }, [])

  // Function to retrieve data by ID from localStorage
  const getDataByIDLocalStorage = () => {
    let data = localStorage.getItem("productData");
    const parsedData = JSON.parse(data);
    console.log("Abcd====", JSON.parse(data));
    if (data) {
      setProductData(JSON.parse(data)); // Parse the data and update the state
      
      let totalPrice = parsedData.reduce((total, item) => {
        return total + (item.data.price * item.quantity);
      }, 0);

      console.log("Total Price:", totalPrice);
      setTotalPrice(totalPrice)
    }
  }

 

  const handleSubmit=()=>{
let login = localStorage.getItem("role");
    if (login){
      console.log("abc=====>>>>", productData);
      let id = localStorage.getItem("id");
      let role = localStorage.getItem("role");
      let orderplaceData = {
        clientid : id,
        role :  "forsale",
        name: localStorage.getItem("firstname") + " " + localStorage.getItem("lastname"),
        product: productData,
        totalprice: TotalPrice
      }
      console.log("abcbbecbeb======", orderplaceData);


      axios.post(`${Env.server}/api/order/neworder`, orderplaceData)
.then((res) => {
  console.log("abc===>>>", res.data)
  console.log("abc===>>>", res.data.message)
  if (res.data.message === "success" ){ 
    localStorage.removeItem("productData");
    setProductData([]);
    setTotalPrice(0);
    setMessage("Product Sell request sent Successfully. Please Check your email"); 
    const timer = setTimeout(() => {
      setMessage("");
      navigate("/");
    }, 5000); 
    return () => clearTimeout(timer);
  }
})
.catch((err) => {
console.log("err======", err); 
})




    }else{
      alert("Please Login First")
    }


  }


  return (
    <>
      <Navbar />
      <div className="mt-5" >
        <h1 className="text-black p-5 text-center" style={{ fontWeight: "bolder" }}>Selling items</h1>
        <div className=" row d-flex" >
         
         
         

          <div className="col-md-5 ml-5">

            {productData?.map((data , index)=>(
              <>

            <div key={index} className="card shadow col-md-12 mb-3" style={{ width: '400px', background: '#f9f9f9', borderColor: "#f9f9f9" }}>
              <div className="row">
                <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={getAsset(data.data.image && data.data.image[0])} className="img-fluid rounded-start"  alt="..." />
                </div>
                <div className="col-md-12">
                  <div className="card-body">
                        <h5 className="fw-bolder d-flex">{data.data.name}<span class=" ml-2 p-1 rounded-pill" >
                          <span className="ml-2 p-1 rounded-pill" style={{cursor:"pointer"}} onClick={() => removeItem(data.data._id)}>
                            <i className="bi bi-trash"></i>
                          </span>
                    </span>
                    </h5>
                        <h5 className="pt-2">Quantity <span className="ml-5">{data.quantity}</span></h5>
                        <h5 className="pt-2">CASH per item <span className="ml-5">£{data.data.price}</span></h5>
                        <h5 className="pt-2">Total <span className="ml-5">£{data.data.price * data.quantity}</span></h5>
                  </div>

                </div>
              </div>
            </div>
              </>
            ))}
            <Link to="/">
            <button type="button" class="btn btn-light border rounded-pill  my-3 ml-2 w-50 custom-button text-white btn-lg" onclick="changeColor()" style={{ background: "black" }} ><i class="bi bi-plus"></i> Add another item</button>
                </Link>


          </div>




          <div className="col-md-4 ">
            <div class=" " style={{ font: "10rem" , backgroundColor:"#071a44" , border:"1px solid transparent " , borderRadius:"10px" }}>
              <div class="card-body tt " style={{ background: "#071a44" }}>
                <h5 class="fs-3 text-center text-white   fw-bolder p-2">Order Summary</h5>
                {/* <a href="#" class="card-link ">TOTAL CASH VALUE</a>
    <a href="#" class="card-link " style={{marginLeft:"40px"}}>£3,383.00</a><br/>
    <a href="#" class="card-link " >TOTAL CASH VALUE</a>
    <a href="#" class="card-link">£3,383.00</a> */}
                <h6 class=" p-2 ml-5 text-white">TOTAL CASH VALUE <span className="ml-5">£{TotalPrice}</span></h6>
                <button type="button" class="btn btn-light border rounded-pill  my-3 ml-5 w-75 p-1 fs-5 custom-button text-black btn-lg" onClick={handleSubmit} style={{ background: "#f9f9f9" }} >Sell</button>
              </div>
            </div>
          <h5 style={{ marginTop:"10px" ,width:"400px", fontWeight: "bolder", color:"#071a44"}}> <center> {Message}</center></h5>
          </div> 
        </div>
      </div ><br /><br />
      <Footer />
    </>
  )
}
export default Order;