import React from "react";
import Navbar from "../Navbar/Navbar";
import Banar from "../Banar/Banar";
import Slider from "../Slider/Slider";
import Footer from "../Footer/Footer";
const Home = () => {
  return (
    <>
      <div className="container-fluid">
        {/* <Navbar /> */}
        <br />
        <Banar />
        <Slider />
        <Footer />
      </div>
    </>
  )
}
export default Home; 