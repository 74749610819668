import './Specification.css'
import { useEffect, useState } from "react";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Env from "../Environment/Env";
import axios from 'axios';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
// import Spec2 from './Spec2';
import { useParams } from 'react-router-dom';
import { getAsset } from '../utils/helper';



const Specification = () => {
    const navigate = useNavigate();
    const { subcatagory, subcatagoryid } = useParams();

    const [Product, setProduct] = useState("product");
    const [ProductID, setProductID] = useState("product");
    const [ItemCatag, setItemCatag] = useState("Items");
    const [ModelCatag, setModelCatag] = useState("Items");
    const [EditionCatag, setEditionCatag] = useState("Items");
    const [StorageCatag, setStorageCatag] = useState("Items");
    const [Condition1, setCondition1] = useState("Items");
    
    const [item, setItem] = useState("");
    const [model, setModel] = useState("");
    const [edition, setEdition] = useState("");
    const [storage, setStorage] = useState("");
    // const systemOptions = ["Good", "Bad", "Poor"];
    
    const [systemOptions, setSystemOptions] = useState([]);
    const [condition, setCondition] = useState(systemOptions[0]);
    const [next, setNext] = useState(1);
    const [ImagesGetById, setImagesGetById] = useState([]);
    const [CheckForYou, setCheckForYou] = useState(false);

    
    
    const [ProdctCatag1, setProdctCatag1] = useState([]);


    const [selectedProduct, setSelectedProduct] = useState(null);

    const [select1, setSelect1] = useState("select");
    const [select2, setSelect2] = useState("select");
    const [select3, setSelect3] = useState("select");
    const [select4, setSelect4] = useState("select");
    const [getAllProductData, setGetAllProductData] = useState([]);
    const [allProductData, setAllProductData] = useState([]);
    console.log(subcatagory, subcatagoryid);
    const [quantity, setQuantity] = useState(1); // State variable to hold quantity
    const [QuantityFinal, setQuantityFinal] = useState(1); // State variable to hold quantity
    const [ClickId, setClickId] = useState("");
    const [AllDataMatch, setAllDataMatch] = useState({});
    const [ShowFinalCart, setShowFinalCart] = useState(false);
    


    useEffect(() => {
        getProductById();
    }, [])

    const getProductById = () => { 
        axios.get(`${Env.server}/api/product/getAllProductOfAdmin`)
            .then((res) => {
                // Get the fetched product data
                setAllProductData(res.data.products);
              
            })
            .catch((err) => {
                console.log("Error fetching product data:", err);
            });
    };

    const handleConditionChange = (event) => {
        setCondition(event.target.value);
        setShowFinalCart(true)
    };
    const SubmitAll = () => {
        let data = {
            item: item,
            model: model,
            edition: edition,
            storage: storage,
            condition: condition,
            addedby: "seller",
            image: ["https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTS1bQtGYrudhxOwbMDZT6CB98nbnYv__dbZKzr7BHCw&s"]
        }
        console.group("abc====>>>>", data);
        axios.post(`${Env.server}/api/product/postNew`, data)
            .then((res) => {
                // console.log("abc===>>>", res.data)
                if (res.data.message === "success") {
                    navigate("/")
                }
            })
            .catch((err) => {
                console.group("err====>>>", err);   
            })
    }

    const handleSellProduct = (data, id, quan) => {
        console.log("quanquanquan....", quan)
        if (quan === 0 || quan === undefined) {
            quan = 1
        }
        console.log("quanquanquan....", quan)

        const existingData = JSON.parse(localStorage.getItem('productData')) || []; // Step 1: Retrieve existing data or initialize as an empty array
        const newData = { data: data, quantity: quan }; // Create new data object

        // Find if the product already exists in the existing data
        const existingIndex = existingData.findIndex(item => item.data._id === id);

        if (existingIndex !== -1) {
            // If product exists, update its quantity
            existingData[existingIndex].quantity = quan;
        } else {
            // If product doesn't exist, add it to the data array
            existingData.push(newData);
        }

        // Step 3: Save the updated data back to the localStorage
        localStorage.setItem('productData', JSON.stringify(existingData));

        setQuantityFinal(quan);
        setClickId(id);

        console.log("abcmuzamal======", data);
    }


 
    // useEffect(() => { 
    //     const productWithIds = allProductData.map(product => ({
    //         id: product._id, 
    //         name: product.name
    //     })); 
    //     setProdctCatag1(productWithIds);
    //     console.log("abc==df==", productWithIds);
    // }, [allProductData]);

    useEffect(() => {
        // Filter products based on the subcategoryId
        const filteredProducts = allProductData.filter(product => product.subcatagoryid === subcatagoryid); // Replace yourSubcategoryId with the actual subcategoryId you want to filter with

        // Create an array of objects containing product names and IDs
        const productWithIds = filteredProducts.map(product => ({
            id: product._id, // Assuming _id is an object with $oid property
            name: product.name,
            subcategoryId: product.subcatagoryid // Assuming there is a subcategoryId in product data
        }));

        // Update state with the filtered array of objects
        setProdctCatag1(productWithIds);
        console.log("Filtered Products:", productWithIds);
    }, [allProductData]);


    useEffect(() => {
        setItemCatag("Items")
        setModelCatag("Items")
        setEditionCatag("Items")
        setStorageCatag("Items")
        setCondition1("Items")
        // Check if ProductID is provided and allProductData is not empty
        if (ProductID && allProductData.length > 0) {
            // Find the product with the matching ID
            const matchingProduct = allProductData.find(product => product._id === ProductID);

            if (matchingProduct) { 
                // Update state with the matching product
                // setSelectedProduct(matchingProduct);
                console.log("Selected Product:", matchingProduct.options);
                if (matchingProduct.options.Item){
                    setItemCatag(matchingProduct.options.Item);
                }
                if (matchingProduct.options.ModelName){
                    setModelCatag(matchingProduct.options.ModelName);
                }
                if (matchingProduct.options.Edition){
                    setEditionCatag(matchingProduct.options.Edition);
                    console.log("abbbbbbbb", matchingProduct.options.Edition)
                } 
                if (matchingProduct.options.Storage){
                    setStorageCatag(matchingProduct.options.Storage);
                } 
                if (matchingProduct.options.Condition) {
                    setCondition1(matchingProduct.options.Condition);
                    setSystemOptions(matchingProduct.options.Condition);
                } 

                setAllDataMatch(matchingProduct)
                console.log("matchingProduct=====", matchingProduct )
                setImagesGetById(matchingProduct.image);
                setCheckForYou(true)
                

            } else {
                console.log("No product found with the provided ID:", ProductID);
            }
        }
    }, [ProductID, allProductData]);



    // const ItemCatag = ["ModelCatag1", "ModelCatag2", "ModelCatag3", "ModelCatag4"];
    // const ModelCatag = ["ModelCatag1", "ModelCatag2", "ModelCatag3", "ModelCatag4"];
    // const EditionCatag = ["EditionCatag", "EditionCatag", "EditionCatag", "EditionCatag", "EditionCatag", "EditionCatag"];
    // const StorageCatag = ["StorafeCatag", "MoStorafeCatag", "ModeStorafeCatag", "ModelStorafeatag", "ModelStorafeag", "ModelCatStorafe"];

    return (
        <>
            <Navbar /><br /><br /><br />
            <div className='body'>
                <div class="ml-5">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="mt-5 ml-3" style={{color: "#071A44"}}>Get your trade-in Estimate</h1>
                            <p className='fs-4 ml-3 mt-3' style={{color: "#071A44"}}> Use our Wizard to quickly find items you want to sell</p>
                        </div>
                    </div>
                </div>
                <div class=" ml-5 mt-2 ai bod" style={{ width: "70%" , height:"auto" }}>
                    <div class="huu">
                        <div class="row mb-3  ml-4" >
                            <div class="col-md-6">
                                <div class="form-container sign-in-container">

                                    <form action="#"> 
                                        <br /> 
                                        <div class="form-group">
                                            {next === 1 ? (
                                                <>
                                                    <label htmlFor="email" style={{color: "#071A44"}}>Product Name:</label><br />
                                                    {ProdctCatag1.length > 7 ? (
                                                        <select className="form-select rounded-pill py-2 lh-lg" id="product" aria-label="Product select example" 
                                                            onChange={(e) => {
                                                                const selectedIndex = e.target.selectedIndex;
                                                                setProduct(`${ProdctCatag1[selectedIndex].name}`);
                                                                setProductID(`${ProdctCatag1[selectedIndex].id}`);
                                                                setNext(next + 1);
                                                                setSelect1("selected");
                                                            }}
                                                        >
                                                            {ProdctCatag1.map((data, index) => (
                                                                <option key={index} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    ) : (
                                                        <>
                                                            {ProdctCatag1.map((data, index) => (
                                                                <button
                                                                    key={index}
                                                                    type="button"
                                                                    className={`btn ${item === data ? 'unselected-button' : 'selected-button'} border custom-button m-1`}
                                                                    onClick={() => {
                                                                        setProduct(`${data.name}`);
                                                                        setProductID(`${data.id}`);
                                                                        setNext(next + 1);
                                                                        setSelect1("selected");
                                                                    }}
                                                                >
                                                                    {data.name}
                                                                </button>
                                                            ))}
                                                        </>
                                                    )}

                                                </>
                                            ) : select1 === "selected" ? (
                                                <>
                                                        <label htmlFor="email" style={{color: "#071A44"}}>Product Name:</label>
                                                    {/* Display the selected button with a different style */}
                                                    <br /><button
                                                        
                                                        type="button"
                                                        className={`btn border custom-button m-1`}
                                                    // disabled
                                                    >
                                                            {Product} 
                                                    </button>

                                                    {/* Display unselected buttons */}
                                                        {ProdctCatag1.length > 7 ? (
                                                            <select
                                                                className="form-select rounded-pill py-2 lh-lg"
                                                                onChange={(e) => {
                                                                    const selectedIndex = e.target.selectedIndex;
                                                                    setProduct(`${ProdctCatag1[selectedIndex].name}`);
                                                                    setProductID(`${ProdctCatag1[selectedIndex].id}`);
                                                                }}
                                                            >
                                                                {ProdctCatag1.map((data, index) => (
                                                                    <option key={index} value={data.id}>
                                                                        {data.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            <div className="d-flex flex-wrap">
                                                                {ProdctCatag1.map((data, index) => (
                                                                    <button
                                                                        key={index}
                                                                        type="button"
                                                                        className={`btn selected-button border custom-button m-1`}
                                                                        onClick={() => {
                                                                            setProduct(`${data.name}`);
                                                                            setProductID(`${data.id}`);
                                                                        }}
                                                                    >
                                                                        {data.name}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        )}

                                                </>
                                            ) : (
                                                <>
                                                            <label for="email">Product Name:</label>
                                                    <select class="form-select rounded-pill py-2 lh-lg" aria-label="Default select example" disabled>
                                                                <option selected>{Product}</option>
                                                    </select>
                                                </>
                                            )} 
                                            <br/> 
                                        </div> 

{CheckForYou === false? (
    <>






                                                <div class="form-group">
                                                    
                                                            {next === 2 ? (
                                                                <>
                                                                </>
                                                            )   : select2 === "select" ? (
                                                                <>
                                                                    <label for="email" style={{color: "#071A44"}}>1.Items</label>
                                                                    <select class="form-select rounded-pill py-2 lh-lg" aria-label="Default select example" disabled>
                                                                        <option selected>{item}</option>
                                                                    </select>
                                                                </>
                                                            ) : null} 



<br/>
                                                    {next === 2 ? (
                                                        <>
                                                        </>
                                                    ) : select2 === "select" ? (
                                                        <>
                                                            <label for="email" style={{color: "#071A44"}}>2.Model Name</label>
                                                            <select class="form-select rounded-pill py-2 lh-lg" aria-label="Default select example" disabled>
                                                                <option selected>{item}</option>
                                                            </select>
                                                        </>
                                                    ) : null}  




                                                    <br />
                                                    {next === 2 ? (
                                                        <>
                                                        </>
                                                    ) : select2 === "select" ? (
                                                        <>
                                                            <label for="email" style={{color: "#071A44"}}>3.Edition</label>
                                                            <select class="form-select rounded-pill py-2 lh-lg" aria-label="Default select example" disabled>
                                                                <option selected>{item}</option>
                                                            </select>
                                                        </>
                                                    ) : null}  


                                                    
                                                            
                                                             
<br/>
                                                    {next === 2 ? (
                                                        <>
                                                        </>
                                                    ) : select2 === "select" ? (
                                                        <>
                                                            <label for="email" style={{color: "#071A44"}}>4.Storage Name</label>
                                                            <select class="form-select rounded-pill py-2 lh-lg" aria-label="Default select example" disabled>
                                                                <option selected>{item}</option>
                                                            </select>
                                                        </>
                                                    ) : null}  





                                                    <br />
                                                    {next === 2 ? (
                                                        <>
                                                        </>
                                                    ) : select2 === "select" ? (
                                                        <>
                                                            <label for="email" style={{color: "#071A44"}}>5.Condition Name</label>
                                                            <select class="form-select rounded-pill py-2 lh-lg" aria-label="Default select example" disabled>
                                                                <option selected>{item}</option>
                                                            </select>
                                                        </>
                                                    ) : null}  






                                                </div>




                                               















































    </>
):(
    <>

                                                    <div class="form-group">
                                                        {ModelCatag === "Items" ? (
                                                            <span onClick={() => setNext(next + 1)}></span>
                                                        ) : (
                                                            <>
                                                                {next === 2 ? (
                                                                    <>
                                                                            <label htmlFor="email"><li>Model Name</li></label>

                                                                            <br />
                                                                        {ModelCatag?.map((data, index) => (
                                                                            <>
                                                                                <button type="button" class="btn btn-light border custom-button m-1" onClick={() => {
                                                                                    setModel(`${data}`);
                                                                                    setNext(next + 1);
                                                                                    setSelect2("selected")
                                                                                }} >{data}</button>
                                                                            </>
                                                                        ))
                                                                        }
                                                                        {/* <br /><br /><label for="email" style={{ marginBottom: "0px" }}>1.Model Name</label> */}
                                                                    </>
                                                                ) : select2 === "selected" ? (
                                                                    <>
                                                                                <label htmlFor="email"><li >Model Name</li></label>
                                                                        {/* Display the selected button with a different style */}
                                                                        <br /><button
                                                                            style={{ backgroundColor: "#bcff00" }}
                                                                            type="button"
                                                                            className={`btn border custom-button m-1`}
                                                                        // disabled
                                                                        >
                                                                            {model}
                                                                        </button>

                                                                        {/* Display unselected buttons */}
                                                                        {ModelCatag?.map((data, index) => (
                                                                            <button
                                                                                key={index}
                                                                                type="button"
                                                                                className={`btn selected-button border custom-button m-1`}
                                                                                onClick={() => {
                                                                                    setModel(`${data}`);
                                                                                }}
                                                                            >
                                                                                {data}
                                                                            </button>
                                                                        ))}
                                                                    </>
                                                                ) : select2 === "select" ? (
                                                                    <>
                                                                                    <label htmlFor="email"><li>Model Name</li></label>
                                                                        <select class="form-select rounded-pill py-2 lh-lg" aria-label="Default select example" disabled>
                                                                            <option selected>{item}</option>
                                                                        </select>
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        )}

                                                    </div>




                                                    {EditionCatag === "Items" ? (
                                                        <span onClick={() => setNext(next + 1)}></span>
                                                    ) : (
                                                        <>
                                                            <div class="form-group">
                                                                {next === 3 ? (
                                                                    <>
                                                                        
                                                                            <label htmlFor="email"><li>Edition</li></label>
                                                                        <br />
                                                                        {EditionCatag?.map((data, index) => (
                                                                            <>
                                                                                <button type="button" class="btn btn-light border custom-button m-1" onClick={() => {
                                                                                    setEdition(`${data}`);
                                                                                    setNext(next + 1);
                                                                                    setSelect3("selected")
                                                                                }} >{data}</button>
                                                                            </>
                                                                        ))
                                                                        }
                                                                        {/* <br /><br /><label for="email" style={{ marginBottom: "0px" }}>1.Model Name</label> */}
                                                                    </>
                                                                ) : select3 === "selected" ? (
                                                                    <>
                                                                                <label htmlFor="email"><li>Edition</li></label>

                                                                        {/* Display the selected button with a different style */}
                                                                        <br /><button
                                                                            style={{ backgroundColor: "#bcff00" }}
                                                                            type="button"
                                                                            className={`btn border custom-button m-1`}
                                                                        // disabled
                                                                        >
                                                                            {edition}
                                                                        </button>

                                                                        {/* Display unselected buttons */}
                                                                        {EditionCatag?.map((data, index) => (
                                                                            <button
                                                                                key={index}
                                                                                type="button"
                                                                                className={`btn selected-button border custom-button m-1`}
                                                                                onClick={() => {
                                                                                    setEdition(`${data}`);
                                                                                }}
                                                                            >
                                                                                {data}
                                                                            </button>
                                                                        ))}
                                                                    </>
                                                                ) : select3 === "select" ? (
                                                                    <>
                                                                                    <label for="email"><li>Edition</li></label>
                                                                        <select class="form-select rounded-pill py-2 lh-lg" aria-label="Default select example" disabled>
                                                                            <option selected>{edition}</option>
                                                                        </select>
                                                                    </>
                                                                ) : null}

                                                            </div>
                                                        </>
                                                    )}




                                                    {StorageCatag === "Items" ? (
                                                        <span onClick={() => setNext(next + 1)}></span>
                                                    ) : (
                                                        <>
                                                            <div class="form-group">
                                                                {next === 4 ? (
                                                                    <>
                                                                            <label for="email"><li>Storage</li></label><br />
                                                                        {StorageCatag?.map((data, index) => (
                                                                            <>
                                                                                <button type="button" class="btn btn-light border custom-button m-1" onClick={() => {
                                                                                    setStorage(`${data}`);
                                                                                    setNext(next + 1)
                                                                                    setSelect3("selected")
                                                                                }} >{data}</button>
                                                                            </>
                                                                        ))
                                                                        }
                                                                        {/* <br /><br /><label for="email" style={{ marginBottom: "0px" }}>1.Model Name</label> */}
                                                                    </>
                                                                ) : select3 === "selected" ? (
                                                                    <>
                                                                                <label htmlFor="email"><li>Storage</li></label>
                                                                        {/* Display the selected button with a different style */}
                                                                        <br /><button
                                                                            style={{ backgroundColor: "#bcff00" }}
                                                                            type="button"
                                                                            className={`btn border custom-button m-1`}
                                                                        // disabled
                                                                        >
                                                                            {storage}
                                                                        </button>

                                                                        {/* Display unselected buttons */}
                                                                        {StorageCatag?.map((data, index) => (
                                                                            <button
                                                                                key={index}
                                                                                type="button"
                                                                                className={`btn selected-button border custom-button m-1`}
                                                                                onClick={() => {
                                                                                    setStorage(`${data}`);
                                                                                }}
                                                                            >
                                                                                {data}
                                                                            </button>
                                                                        ))}
                                                                    </>
                                                                ) : select3 === "select" ? (
                                                                    <>
                                                                                    <label for="email"><li>Storage</li></label>
                                                                        <select class="form-select rounded-pill py-2 lh-lg" aria-label="Default select example" disabled>
                                                                            <option selected>{storage}</option>
                                                                        </select>
                                                                    </>
                                                                ) : null}

                                                            </div>
                                                        </>
                                                    )}

                                                    {Condition1 === "Items" ? (
                                                        <span onClick={() => setNext(next + 1)}></span>
                                                    ) : (
                                                        <>
                                                            <div class="form-group">
                                                                <div>
                                                                        <label htmlFor="email"><li>Condition</li></label>
                                                                    <div>
                                                                        {/* Dynamically generate radio buttons */}
                                                                        {systemOptions.map((option, index) => (
                                                                            <div key={index}>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`condition${index + 1}`}
                                                                                    name="condition"
                                                                                    value={option}
                                                                                    checked={condition === option}
                                                                                    onChange={handleConditionChange}

                                                                                />
                                                                                <label htmlFor={`condition${index + 1}`}>{option}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

    </>
)}

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >


                <br />




                
                {ShowFinalCart === true ? (
                    <>
                <div class="ml-5">
                    <h5 class=" ml-3 fw-bolder pt-4 fs-4" style={{color: "#071A44"}}>Item You Want to sell</h5>
                </div>
                        <div class=" ml-5 mt-3" style={{ maxWidth: "50%" }}>

                            {/* -----------------------------------second card----------------------- */}
                            <div class="card-body  rounded fs-5 " style={{ background: "#f7f7f7" }} >
                                <div class="row">
                                    <div className="col-md-4 image  bg-white" style={{ background: '#ffffff', borderRadius: '10px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                            <img
                                                src={getAsset(`${ImagesGetById[0]}`)}
                                                alt="Image"
                                                className="img-fluid ml-5"
                                                style={{ maxWidth: '80%', maxHeight: '100%', objectFit: 'contain' }}
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-8 ">
                                        <p style={{ color: "#071A44" }}>{item}</p>
                                        <p>{model}  , {storage},{item}<br /> {edition}, {condition}</p>
                                        <p>Cash<br /> ${AllDataMatch.price}</p>
                                        <div class="col-md-4 ml-auto ">
                                            <button type="button" onClick={() => handleSellProduct(AllDataMatch, AllDataMatch._id, 1)} class="btn btn-light border rounded-pill w-75  custom-button text-white btn-lg" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" href="#offcanvasExample" role="button" style={{ background: "#071A44" }}  >Sell</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* -----------------------------second card end------------------- */}
                        </div>
                    </>
                ):null}
               
                <div class="ml-5 mt-5">
                    <h1 style={{color: "#071A44"}}>Cant't find your item</h1>
                    <div class="row no-gutters">
                        <div class="col-md-4 mt-3 mb-3">
                            <button type="button" style={{color: "#071A44", border: "2px solid #071A44"}} class="btn btn-light border custom-button-black mb-3 mr-3 rounded-pill btn-lg" onclick="changeColor()">Start Over</button>
                            <button type="button" style={{color: "#071A44", border: "2px solid #071A44"}} class="btn btn-light border custom-button-black mb-3 mr-3 rounded-pill btn-lg" onclick="changeColor()">Search for an item</button>
                            <button type="button" style={{color: "#071A44", border: "2px solid #071A44"}} class="btn btn-light border custom-button-black mb-3 mr-3 rounded-pill btn-lg" onclick="changeColor()">Contact Us</button>
                        </div>

                    </div>


                </div>













            </div >

            {/* --------------------------------------Sidebar------------------------------------ */}



            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ borderBottomLeftRadius: "50px", borderTopLeftRadius: "50px" }}>
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel"></h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body ml-4">
                    <h1 style={{ color: "#071A44" }}>Item added to sell</h1>    
                    
                    <Link to="/Cart ">
                        <button type="button" class="btn btn-light border rounded-pill w-100  custom-button text-white btn-lg" style={{ background: "#071A44" }} onClick={SubmitAll}>Complete Your Sale</button><br /><br />
                    </Link>
                    <button type="button"  class="btn btn-light border rounded-pill w-100  custom-button btn-lg"    >Add more items</button>
                </div>
            </div>

            <Footer />



        </>

    )
}
export default Specification;   