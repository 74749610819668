import React , {useState, useEffect} from "react";
import './Fiter.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import Env from "../Environment/Env";
import axios from "axios";
import { Link } from "react-router-dom";
import { getAsset } from "../utils/helper";


const Filter = () => {
    const { SCatagory } = useParams();   

    const [allProductData1, setAllProductData1] = useState([]);
    const [allProductData, setAllProductData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [FilteredData, setFilteredData] = useState([]);
    const [ClickId, setClickId] = useState("");

    const [quantity, setQuantity] = useState(1); // State variable to hold quantity
    const [QuantityFinal, setQuantityFinal] = useState(1); // State variable to hold quantity
    


    // Function to handle increasing the quantity


    useEffect(() => {
        getProductById();
    }, [SCatagory])

    useEffect(() => {
        getProductById();
    }, [])


    const handleSellProduct = (data ,id , quan) => {
        // console.log("quanquanquan....", quan)
        if (quan === 0 || quan === undefined  ){
            quan = 1
        }
        // console.log("quanquanquan....", quan)

        const existingData = JSON.parse(localStorage.getItem('productData')) || []; // Step 1: Retrieve existing data or initialize as an empty array
        const newData = { data: data, quantity: quan }; // Create new data object

        // Find if the product already exists in the existing data
        const existingIndex = existingData.findIndex(item => item.data._id === id);

        if (existingIndex !== -1) {
            // If product exists, update its quantity
            existingData[existingIndex].quantity = quan;
        } else {
            // If product doesn't exist, add it to the data array
            existingData.push(newData);
        }

        // Step 3: Save the updated data back to the localStorage
        localStorage.setItem('productData', JSON.stringify(existingData)); 
        setQuantityFinal(quan);
        setClickId(id); 
        // console.log("abcmuzamal======", data);
    }

   
    


    useEffect(() => {
        // Retrieve saved selected filters from localStorage when the component mounts
        const savedFilters = localStorage.getItem('selectedFilters');
        if (savedFilters) {
            setSelectedFilters(JSON.parse(savedFilters));
        } 
    }, []);

    useEffect(() => {
        let getdatafiltercounting = localStorage.getItem("filterselected");
        // alert(selectedFilters.length + "    " + getdatafiltercounting);  
        if (!allProductData) {
            // console.log("selectedFilters====tt", selectedFilters); 
        } else if( selectedFilters.length > 0) {
            const uniqueProducts = []; // Array to store unique products

            selectedFilters.forEach((filter) => {
                const uniqueIds = new Set(); // Create a Set to store unique IDs
                const filterid = filter.id;

                filterid.forEach((idfilter) => {
                    const id = idfilter[0]; // Extract the ID 
                    if (!uniqueIds.has(id)) { // Check if the ID is not already in the Set 
                        const product = allProductData.find((product) => product._id === id);
                        if (product) { // Check if product exists
                            uniqueProducts.push(product); // Push the unique product to the array
                        }
                    }
                });
            });
            const uniqueSet = new Set(uniqueProducts);
            
            // Convert the Set back to an array if needed
            const uniqueArray = Array.from(uniqueSet);
            if (uniqueArray){
                setAllProductData(uniqueArray);
            } 
        }  

    }, [selectedFilters]);



    const handleCheckboxClick = (filterVar, productId) => {
        setSelectedFilters(prevFilters => {
            console.log("prevFilters===================", prevFilters)
            const selectedIndex = prevFilters.findIndex(filter => filter.var === filterVar);

            // Create a Set to store unique IDs
            const uniqueIds = new Set(prevFilters.flatMap(filter => filter.id));

            if (selectedIndex === -1) {
                // If the filter is not already selected, add it to the selectedFilters state
                const newFilters = [...prevFilters, { var: filterVar, id: [productId] }];
                uniqueIds.add(productId); // Add the ID to the Set  
                localStorage.setItem('selectedFilters', JSON.stringify(newFilters));
                localStorage.setItem("filterselected", newFilters.length);
                window.location.reload(); 
                return newFilters;
            } else {
                // If the filter is already selected, remove it from the selectedFilters state
                const newFilters = prevFilters.filter((_, index) => index !== selectedIndex);
                let valprevfilter = localStorage.getItem("filterselected"); 
                uniqueIds.delete(productId); // Remove the ID from the Set
                console.log("filterrrrrrrrrrrrrrrrrrrrrrrr", newFilters)
                if (newFilters.length === 0) { 
                    setAllProductData(allProductData1);
                }else if(newFilters.length > 0) {
                    // alert(newFilters.length ,"    p", prevFilters.length)
                    if (newFilters.length < valprevfilter) {
                        localStorage.setItem("filterselected", newFilters.length);
                        window.location.reload();
                        // alert(newFilters.length +  "   j " + valprevfilter );
                    } else if (newFilters.length > valprevfilter) {
                        alert("increase")
                        localStorage.setItem('selectedFilters', JSON.stringify(newFilters));
                        localStorage.setItem("filterselected", newFilters.length);
                        // const filteredProducts = allProductData.filter(product => {
                        //     return newFilters.every(filter => filter.id.every(id => product._id === id));
                        // });
                        // console.log("filteredProducts========", filteredProducts);
                        // setAllProductData(filteredProducts);
                    } 
                } 
                // Save selected filters to localStorage
                localStorage.setItem('selectedFilters', JSON.stringify(newFilters));
                return newFilters;
            }
        });
    };



        useEffect(() => {
            // Log the IDs of all selected filters after each state update
            const selectedIds = selectedFilters.flatMap(filter => filter.id);
            console.log("Selected IDs:", selectedIds);
        }, [selectedFilters]);













    const getProductById = () => {
        axios.get(`${Env.server}/api/product/getDataByName1/${SCatagory}`)
            .then((res) => {
                // Get the fetched product data
              

                if(localStorage.getItem("filterselected") === 0){
                    console.log("");
                }else{ 
                    const savedFilters = localStorage.getItem('selectedFilters'); 
                    if (savedFilters) {
                        setSelectedFilters(JSON.parse(savedFilters));
                    } 
                }
                setAllProductData(res.data.products)
                setAllProductData1(res.data.products)

                let data = res.data.products;
                // console.log("hhhhhhhhhhhhhhhh======", data);
                const combinedFilters = {};

                data.forEach((product) => {
                    if (product.filter) {
                        product.filter.forEach((filterCategory) => {
                            Object.entries(filterCategory).forEach(([key, values]) => {
                                if (values.length === 0) return; // Skip if no data

                                if (!combinedFilters[key]) {
                                    combinedFilters[key] = [];
                                }
                                values.forEach((value) => {
                                    let existingFilter = combinedFilters[key].find(
                                        (existing) => existing.var === value
                                    );
                                    if (!existingFilter) {
                                        combinedFilters[key].push({ var: value, id: [product._id] });
                                    } else {
                                        existingFilter.id.push(product._id);
                                    }
                                });
                            });
                        });
                    }
                });

                console.log("Combined filters:", combinedFilters);
                setFilteredData(combinedFilters);
            })
            
            .catch((err) => {
                console.log("Error fetching product dataaaaa:", err);
            });
    };

    const [productQuantities, setProductQuantities] = useState({}); // State variable to hold quantities for each product

    // Function to handle increasing the quantity for a specific product
    const increaseQuantity = (productId) => {
        setProductQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 0) + 1
        }));
    };

    // Function to handle decreasing the quantity for a specific product
    const decreaseQuantity = (productId) => {
        if (productQuantities[productId] && productQuantities[productId] > 0) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: prevQuantities[productId] - 1
            }));
        }
    };

    // Function to handle changing the quantity directly for a specific product
    const handleQuantityChange = (productId, event) => {
        const value = parseInt(event.target.value);
        if (!isNaN(value) && value >= 1) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: value
            }));
        }
    };




    const handleClick = () => {
        // console.log("clicked====", ClickId );

    }



    return (
        <>
            <Navbar /><br /><br /><br />

            <div className="ml-5 mr-5 pt-5 ">
                
                <div className="d-flex  justify-content-between mx-5">
                    <p style={{ font: "1.5rem" }}>Showing 1-{allProductData.length} results</p>
                    
                </div> 
                <div className="row ">
                    <div className="col-lg-3 col-md-2">  
                        <div>
                            {/* Map over the filtered data and create checkboxes */}
                            {FilteredData && Object.entries(FilteredData).map(([filterName, filterValues]) => (
                                <div className="box w-75 my-4">
                                    <h5 className="text-black border-top pt-3">{filterName}</h5>
                                    <div className="p-2">
                                        {/* Map over filter values and create checkboxes */}
                                        {filterValues.map(filter => (
                                            <div key={filter.var} className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={filter.var}
                                                    checked={selectedFilters.some(selectedFilter => selectedFilter.var === filter.var)}
                                                    onClick={() => handleCheckboxClick(filter.var, filter.id)}
                                                />
                                                <label className="form-check-label" htmlFor={filter.var}>
                                                    {filter.var}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>

                    

                      

                 
 
                       
                    </div>

                    {/* --------------------------------product card--------------------- */}
                    {/* <div className="container-fluid col">
                        <div className="row " style={{ }}>
                            {allProductData?.map((data, index) => ( 
                                <div key={index} className="col d-flex align-content-lg-stretch my-2 bcolor">
                                    <div className="col">
                                        <div className="card-body card bccolor" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "103%" }}>
                                            <img src={getAsset(data.image[0])} style={{ marginRight: "10px", height: '140px' }} className=" rounded ml-5 justify-content-center align-items-center" alt="" />
                                        </div>
                                        <div className="ml-3 pt-4">
                                            <h6 style={{ color: "rgb(86, 87, 88)" }}>{data.name}</h6>
                                            <h6>{data.options.ModelName[0]} {"  "} ,{"  "} {data.options.Edition && data.options.Edition[0]} ,{"  "} {data.options.Condition && data.options.Condition[0]}</h6>
                                            <div className="row align-items-center">
                                                <div className="col-lg-6" style={{ color: "rgb(86, 87, 88)" }}>
                                                    <h6>CASH</h6>
                                                    <h5>£{data.price}</h5>
                                                </div>
                                                <div className="col-lg-6 mt-3">
                                                    <div className="input-group">
                                                        <button className="btn btn-outline-secondary" type="button" onClick={() => decreaseQuantity(data._id)}>-</button>
                                                        <input type="text" className="form-control"  value={productQuantities[data._id] || 1} onChange={(e) => handleQuantityChange(data._id, e)}  readOnly/>
                                                        <button className="btn btn-outline-secondary" type="button" onClick={() => increaseQuantity(data._id)}>+</button>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <button type="button" onClick={() => handleSellProduct(data , data._id, productQuantities[data._id])} className="btn btn-light border rounded-pill w-100 custom-button text-white btn-lg" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" href="#offcanvasExample" role="button" style={{ background: "#071A44" }}>Sell</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}
                    
                    <div className="container-fluid col">
                        <div className="row " style={{}}>
                            {allProductData?.map((data, index) => (
                                <div key={index} className="col d-flex align-content-lg-stretch my-2 bcolor">
                                    <div className="col">
                                        <div className="card-body card bccolor mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "103%" }}>
                                            <img src={getAsset(data.image[0])} style={{ marginRight: "10px", height: '100px' }} className=" rounded ml-5 justify-content-center align-items-center" alt="" />

                                            <div className="card-body card bccolor" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "103%" }}>
                                                <img src={getAsset(data.image[0])} style={{ marginRight: "10px", height: '140px' }} className=" rounded ml-5 justify-content-center align-items-center" alt="" />

                                            </div>
                                            <div className="ml-3 pt-4">
                                                <h6 style={{ color: "rgb(86, 87, 88)" }}>{data.name}</h6>
                                                <h6>{data.options.ModelName[0]} {"  "} ,{"  "} {data.options.Edition && data.options.Edition[0]} ,{"  "} {data.options.Condition && data.options.Condition[0]}</h6>
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6" style={{ color: "rgb(86, 87, 88)" }}>
                                                        <h6>CASH</h6>
                                                        <h5>£{data.price}</h5>
                                                    </div>
                                                    <div className="col-lg-6 mt-3">
                                                        <div className="input-group">
                                                            <button className="btn btn-outline-secondary" type="button" onClick={() => decreaseQuantity(data._id)}>-</button>
                                                            <input type="text" className="form-control" value={productQuantities[data._id] || 1} onChange={(e) => handleQuantityChange(data._id, e)} readOnly />
                                                            <button className="btn btn-outline-secondary" type="button" onClick={() => increaseQuantity(data._id)}>+</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <button type="button" onClick={() => handleSellProduct(data, data._id, productQuantities[data._id])} className="btn btn-light border rounded-pill w-100 custom-button text-white btn-lg" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" href="#offcanvasExample" role="button" style={{ background: "#071A44" }}>Sell</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                                </div>
                    </div> 


                </div>

            </div>




            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ borderBottomLeftRadius: "50px", borderTopLeftRadius: "50px" }}>
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel"></h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body ml-4">
                    <h1>Item added to sell</h1>
                  
                    <Link to={`/Cart`}>
                        <button type="button" class="btn btn-light bord er rounded-pill w-100  custom-button text-black btn-lg" style={{ background: "#bcff00" }}  >Complete Your Sale</button><br /><br />
                    </Link>
                    <Link to={`/`}>
                    <button type="button" class="btn btn-light border rounded-pill w-100  custom-button text-black btn-lg"    >Add more items</button>
                    </Link>

                </div>
            </div>

            <Footer />
        </>
    )
}
export default Filter;