import React, { useEffect, useState } from "react";
import "./singin.css"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import Env from "../Environment/Env";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Singin = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [EmailforgotPass, setEmailforgotPass] = useState("");
    
    useEffect(() => {
        localStorage.clear();
    }, [])

    const handleChange = (e) => {
        let obj = data;
        obj[e.target.name] = e.target.value;
        setData(obj);
    }

    const submit = () => {
        console.log("abc====", data)
        axios.post(`${Env.server}/api/user/login`, data)
            .then((res) => {
                const resp = res.data.response;
                console.log("abc===>>>", res.data)
                localStorage.setItem("id", resp._id)
                localStorage.setItem("firstname", resp.firstname)
                localStorage.setItem("lastname", resp.lastname)
                localStorage.setItem("phNum", resp.phNum)
                localStorage.setItem("email", resp.email)
                if (resp.firstname === "admin") {
                    localStorage.setItem("role", "admin");
                    navigate("/");
                } else if (resp.firstname !== "admin") {
                    localStorage.setItem("role", "seller");
                    navigate("/");
                }
            })
            .catch((err) => {
                console.log("abc===>>>", err);
            })
    }


    const forgotFun=()=>{
        axios.get(`${Env.server}/api/user/forgotPassword/${EmailforgotPass}` )
        .then((res)=>{
            console.log("Abc=====", res.data);
            window.open("https://mail.google.com/", "_blank");
        })
        .catch((err) => {
            console.log("err", err)
        })
    }
    return (
        <>
            <Navbar /><br /><br /><br />
            <div class="container register-form pt-5   w-50 ">
                <div class="form justify-content-center align-items-center">
                    <div class="form-content ">
                        <h1 className="fs-7" style={{color: '#071A44'}}>Sign in</h1>
                        <p style={{ font: "20px",color: '#071A44' }}>Don't have an account?<Link to="/Singup" class="about-link"><span className="fw-bolder">Sign up</span></Link> </p>
                        <div class="row pt-4">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="Email Address" className="fs-9" style={{color: '#071A44'}}>Email Address*</label>
                                    <input type="text" class="form-control" placeholder="Email Address" onChange={handleChange} name="email" />
                                </div>
                                <div class="form-group">
                                    <label for="Password " className="fs-9" style={{color: '#071A44'}}> Password*</label>
                                    <input type="password" class="form-control" placeholder="Password" onChange={handleChange} name="password" />
                                </div> 
                            </div>

                        </div>
                        <div class="form-check pb-2">
                            <label className="delete-icon"
                                data-toggle="modal"
                                data-target="#exampleModalLong1" class="form-check-label"  style={{color: '#071A44' , cursor:"pointer"}} >
                                Forgot your password?
                            </label>
                        </div>
                        <button type="button" class="btnSubmit btn-lg mt-2 fw-bolder fs-9" style={{backgroundColor: '#FFFFFF', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', color: '#071A44'}} onClick={submit}>Sign in</button>
                    </div>
                </div>
            </div>
            <Footer />


            <div class="modal fade" id="exampleModalLong1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Email</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
<input type="email" onChange={(e)=>setEmailforgotPass(e.target.value)} class="form-control" placeholder="Email Address"/>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button"
                                class="btn btn-primary"
                                data-dismiss="modal"
                                onClick={forgotFun}
                                   >Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Singin;