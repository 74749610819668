import React from "react";
import { useEffect } from "react";
import './Aboutus.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
const Aboutus = () => {

  useEffect(() => {

    class Menu {
      //Constructor for Menu class
      constructor(_name, _activateID, _fa) {
        this.name = _name;
        this.activateID = _activateID;
        this.fa = _fa;
      }
      //Return an HTML element representation of the Menu
      GetHTMLElement = function () {
        var element = document.createElement("div");
        element.className = "menu";
        var p = document.createElement("p");

        var iT = document.createElement("i");
        iT.className = this.fa;
        p.appendChild(iT);

        p.innerHTML += " " + this.name;
        var i = document.createElement("i");
        i.className = "fa-solid fa-arrow-right";
        element.appendChild(p);
        element.appendChild(i);
        return element;
      };
    }

    //Define arrays for menus and pages
    var menus = [];
    var pages = [];

    //function for initializing, populating menu and page arrays
    function populateMenus() {
      menus.push(new Menu("What is QCL?", "/"));
      menus.push(new Menu("Sell Phones"));
      menus.push(new Menu("Sell Games & Consoles"));
      menus.push(new Menu("Sell Tech", "/info/about"));
      menus.push(new Menu("CeX History"));
      menus.push(new Menu("QCL People"));
      menus.push(new Menu("Press Clippings"));
      menus.push(new Menu("About Paul"));
      menus.push(new Menu("CeX Logos"));
      menus.push(new Menu("Gender Pay Gap"));
      menus.push(new Menu("Modern Slavery Statement"));
      menus.push(new Menu("Our COVID-19 Response"));
      menus.push(new Menu("Counterfeit Products Policy"));






      pages.push(document.getElementById("home"));
      pages.push(document.getElementById("settings"));
      pages.push(document.getElementById("about"));
      pages.push(document.getElementById("SellTech"));
      pages.push(document.getElementById("CeXHistory"));
      pages.push(document.getElementById("QCLPeople"));
      pages.push(document.getElementById("PressClippings"));
      pages.push(document.getElementById("AboutPaul"));
      pages.push(document.getElementById("CeXLogos"));
      pages.push(document.getElementById("GenderPayGap"));
      pages.push(document.getElementById("ModernSlaveryStatement"));
      pages.push(document.getElementById("OurCOVID-19Response"));
      pages.push(document.getElementById("CounterfeitProductsPolicy"));



    }

    //Call functions and open first page
    populateMenus();
    displayUpdatedMenus();
    open(0);


    //update and render menus
    function displayUpdatedMenus() {
      var sidebarElement = document.getElementById("sidebar");
      var sidebarMenus = sidebarElement.querySelectorAll(":scope > .menu");
      for (let i = 0; i < sidebarMenus.length; i++) {
        sidebarMenus[i].remove();
      }
      for (let i = 0; i < menus.length; i++) {
        var element = menus[i].GetHTMLElement();
        sidebarElement.appendChild(element);
        element.addEventListener("click", function () {
          open(i);
        });
      }
    }


    //function for opening page on menu click
    function open(index) {
      for (let i = 0; i < pages.length; i++) {
        pages[i].style.display = "none";
      }
      pages[index].style.display = "block";

    }

  }, [])
  return (
    <>
      <Navbar /><br /><br /><br /><br />
      <section  style={{ background: "#071A44" }}>

        <div class="allpage  ">

          <div id="sidebar" class="sidebar  " >
            <h1 class="title " style={{ background: "#071A44", color: "#FFFFFF" }}>About Us</h1>
          </div>
          {/* <!-- ------------------------------QCL--------------------- --> */}
          <div id="home" class="page ">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>What is QCL?</h1>
              <input type="checkbox" />
              <img src="/image/main.png" alt="" className="imagesize" />
            </div>
            <br /><br /><br />
            <p class="paragraph ">QCL is the UK’s biggest second-hand electronics and entertainment specialist. You can buy, sell and exchange your games, phones, consoles, laptops, tablets, computers, electronics & gadgets for cash.Starting as a single games and computer specialist shop just off of London’s Tottenham Court Road in 1992, QCL has expanded over 30 years to more than 600 stores worldwide, spanning over 11 countries including the UK, Ireland, Spain, Portugal, Mexico, The Canaries, Malaysia, The Netherlands, Australia, India and Italy. CeX has been serving tech enthusiasts, gamers and geeks the latest and greatest in the world’s tech and gaming - all with a 24 month warranty, for decades.At CeX you can get cash fast for your old phones, games, consoles, laptops, tablets, computers, technology, DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and upgrade your old phones, consoles and laptops for less. With 385+ stores in the UK, you can find your local store here.Upgrade your tech for less by trading your unwanted tech in at QCL, and grabbing yourself that new phone, new games, consoles, laptops and tech. You can shop in-store, online and now you can skip the queues and make sure you reserve your goodies with Click & Collect at QCL.</p>
            <p class="paragraph ">QCL is the UK’s biggest second-hand electronics and entertainment specialist. You can buy, sell and exchange your games, phones, consoles, laptops, tablets, computers, electronics & gadgets for cash.Starting as a single games and computer specialist shop just off of London’s Tottenham Court Road in 1992, QCL has expanded over 30 years to more than 600 stores worldwide, spanning over 11 countries including the UK, Ireland, Spain, Portugal, Mexico, The Canaries, Malaysia, The Netherlands, Australia, India and Italy. CeX has been serving tech enthusiasts, gamers and geeks the latest and greatest in the world’s tech and gaming - all with a 24 month warranty, for decades.At CeX you can get cash fast for your old phones, games, consoles, laptops, tablets, computers, technology, DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and upgrade your old phones, consoles and laptops for less. With 385+ stores in the UK, you can find your local store here.Upgrade your tech for less by trading your unwanted tech in at QCL, and grabbing yourself that new phone, new games, consoles, laptops and tech. You can shop in-store, online and now you can skip the queues and make sure you reserve your goodies with Click & Collect at QCL</p>
            <p class="paragraph">CL is the UK’s biggest second-hand electronics and entertainment specialist. You can buy, sell and exchange your games, phones, consoles, laptops, tablets, computers, electronics & gadgets for cash.Starting as a single games and computer specialist shop just off of London’s Tottenham Court Road in 1992, QCL has expanded over 30 years to more than 600 stores worldwide, spanning over 11 countries including the UK, Ireland, Spain, Portugal, Mexico, The Canaries, Malaysia, The Netherlands, Australia, India and Italy. CeX has been serving tech enthusiasts, gamers and geeks the latest and greatest in the world’s tech and gaming - all with a 24 month warranty, for decades.At CeX you can get cash fast for your old phones, games, consoles, laptops, tablets, computers, technology, DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and upgrade your old phones, consoles and laptops for less. With 385+ stores in the UK, you can find your local store here.Upgrade your tech for less by trading your unwanted tech in at QCL, and grabbing yourself that new phone, new games, consoles, laptops and tech. You can shop in-store, online and now you can skip the queues and make sure you reserve your goodies with Click & Collect at QCL.</p>
            <p class="paragraph">
              <p class="paragraph">
                QCL is the UK’s biggest second-hand electronics and entertainment specialist. You can buy, sell and exchange your games, phones, consoles, laptops, tablets, computers, electronics & gadgets for cash.Starting as a single games and computer specialist shop just off of London’s Tottenham Court Road in 1992, QCL has expanded over 30 years to more than 600 stores worldwide, spanning over 11 countries including the UK, Ireland, Spain, Portugal, Mexico, The Canaries, Malaysia, The Netherlands, Australia, India and Italy. CeX has been serving tech enthusiasts, gamers and geeks the latest and greatest in the world’s tech and gaming - all with a 24 month warranty, for decades.At CeX you can get cash fast for your old phones, games, consoles, laptops, tablets, computers, technology, DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and upgrade your old phones, consoles and laptops for less. With 385+ stores in the UK, you can find your local store here.Upgrade your tech for less by trading your unwanted tech in at QCL, and grabbing yourself that new phone, new games, consoles, laptops and tech. You can shop in-store, online and now you can skip the queues and make sure you reserve your goodies with Click & Collect at QCL.
              </p>
            </p>
          </div>
          {/* <!-- -------------------------------Sell phones------------------------ --> */}

          <div id="settings" class="page">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>Sell Phones</h1>
              <input type="checkbox" />
              <img src="/image/main.png" alt="" className="imagesize" />
            </div>
            <h1 class="pt-3" style={{ color: "#FFFFFF" }}>Wanted: iPhones!</h1>
            <h6 style={{ color: "#FFFFFF" }}>Get cash for your tech the easy way at CeX. With new iPhones on the way, now is the best time to sell your phone for the best price. Discover the value of your old tech at CeX and get your next phone for less.</h6>

            <br /><br />
            <div class="card-deck wimage" >
              <div class="card" >
                <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >iPHONE 13 Pro Max</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >iPHONE 13 Pro Max</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >iPHONE 13 Pro Max</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >iPHONE 13 Pro Max</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>

            </div><br /><br />
            {/* <!-- --------------------------------second card----------------------- --> */}
            <div class="card-deck wimage">
              <div class="card" >
                <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >iPHONE 13 Pro Max</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >iPHONE 13 Pro Max</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >iPHONE 13 Pro Max</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >iPHONE 13 Pro Max</h5>
                </div>

              </div>

            </div>
            <h4 class="pt-4" className="fs-5 mt-5 mb-2" style={{ color: "#FFFFFF" }}>Join over 2 Million QCL App users today!</h4>
            <div class="row">
              <div class="col-12 col-md-4">
                <button type="button" class="btn btn-lg p-3 ml-4 pt-2 w-100 w-md-25" style={{ background: "#FFFFFF", color: "#071A44" }}>All other iPhones</button>
              </div>
            </div>


          </div>

          {/* <!-- --------------------------------sell Tech------------------------ --> */}
          <div id="SellTech" class="page">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>Sell Tech</h1>
              <input type="checkbox" />
              <img src="/image/main.png" alt="" className="imagesize" />
            </div>
            <h1 class="pt-3" style={{ color: "#FFFFFF" }}>Sell Tech for CASH</h1>
            <h6 style={{ color: "#FFFFFF" }}>Don't let your unwanted tech gather dust. Get top cash for it in-store or online today! Click the buttons below to see how much you could get.</h6>
            <br /><br />
            <div class="card-deck wimage">
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/1.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Laptop</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/2.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Desktop</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/3.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Headphones</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/4.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Camera Lenses</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>

            </div><br /><br />
            {/* <!-- --------------------------------second card----------------------- --> */}
            <div class="card-deck wimage">
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/1.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Laptop</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/2.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Desktop</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/3.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Headphones</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/4.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Camera Lenses</h5>
                </div>

              </div>

            </div>
            <h4 class="pt-4" className="fs-5 mt-5 mb-2" style={{ color: "#FFFFFF" }}>Join over 2 Million QCL App users today!</h4>
            <div class="row">
              <div class="col-12 col-md-4">
                <button type="button" class="btn btn-lg p-3 ml-4 pt-2 w-100 w-md-25" style={{ background: "#FFFFFF", color: "#071A44" }} >All other CASH</button>
              </div>
            </div>

          </div>

          {/* <!-- ------------------------------------CeXHistory------------------------------- --> */}
          <div id="CeXHistory" class="page">
            <h1 style={{ color: "#FFFFFF" }}>QCL History</h1>
            <p class="paragraph ">Crumbs. Doesn't time fly? It seems like only yesterday when CeX opened its doors for the first time. From little nippers to the oldest kids in the classroom, the founders still run the show, keeping it fiercely independent, passionately un-corporate, yet always professional. Well, we don't really run the show, we just pretend to; our custies who shop with us and now work with us run the show. Our testers, sales assistants, supervisors and management run this very special show in a very special way.</p>
            <p class="paragraph ">Humour us, please, while we take a misty-eyed meander down memory lane. Don't worry... we'll spare you the photo album.Started by Robert Dudani (Drinking Buddy), Paul Farrington (Gig Buddy), Hugh Man (Zen Buddy), Charlie Brooker (Cynical Student Type Buddy), Oli Smith (Genius Buddy) and Oliver Ball (The Consigliere).</p>
            <p class="paragraph">A long, long time ago, in a galaxy far, far away - well, 1992 in London to be exact, as we couldn't find Tatooine - we had three grand in our back pocket and a firm belief that there is a second hand market for computers, gadgets and video games. Increasing obsolescence was rapidly increasing the need to recycle.</p>
            <p class="paragraph">We also thought that the staff at Rumbelows were crap - we, as custies, wanted to know what we were buying, in ever-increasing technical gadgets and silicon, and ever-increasing catalogues of games and movies.</p>
            <p class="paragraph">
              We couldn't afford the most exotic kit or get rid of our old kit. What's a geek to do?
            </p>
            <p class="paragraph">
              We wanted a retailer where one could pick up second hand oldies-but-still-goodies at knockdown prices. And we genuinely all cared about the environment - we're hippies, but dressed unhippyishly stupid as to not admit we like the Grateful Dead, Syd and Led Zeppelin which we know everyone secretly does.
            </p>
            <p class="paragraph">
              We also didn't want a store stuffing rubbish in our faces, a retailer that colluded with software houses and hardware manufacturers to hard flog what they couldn't shift. We would sell everything at the market price, if something doesn't sell, we reduce the price and if it sells too well, we increase the price. Simple market economics.
            </p>
            <p class="paragraph">
              The first CeX (Complete Entertainment Exchange), then known as the easy off the tongue 'Tottenham Court Road Computer Exchange' opened in a small shop at the top of Whitfield Street. Tucked behind the top end of Tottenham Court Road - London's electronics and computer heartland - CeX's mix of cheap second hand games, hard to find Japanese and US imports and computer bits and bobs, soon found a fanatically loyal band of customers dubbed affectionately as custies. Many of these custies have since joined the company as it has blossomed.
            </p>
            <p class="paragraph">
              It became clear that we'd need to open a new store to keep up with demand (and supply). Our Rathbone Place shop opened in 1993 taking over Pilot Software City, a place well known to London based Apple IIe and Atari 800 gamers. CeX took over and concentrated on console gaming - SNES, Neo, Megadrive, Engine and Game Boy, leaving the Whitfield Street branch to focus on the computing side of the geek within. Pilot and CeX has been selling games at this location continuously for over 30 years - the longest-running gaming location in the world? Let us know if you know differently, we'd love to find out.
            </p>
            <p class="paragraph">
              1995 saw a new flagship Computing store slap-bang in the middle of Tottenham Court Road.
            </p>
            <p class="paragraph">
              n 1997 we opened our Birmingham branch, the first outside of London.
            </p>


          </div>
          {/* <!-- -------------------------------QCLPeople----------------------- --> */}
          <div id="QCLPeople" class="page">
            <h1 style={{ color: "#FFFFFF" }}> QCL People</h1>
            <div class="product-desc" style={{ color: "#FFFFFF" }}>
              <p class="leads">Have you fallen desperately and deeply in love with a CeX employee and want to put a name to the face? Don't worry, it happens all the time*. Use this section find contact details for people in all parts of the CeX empire.
                *Not strictly true.</p>
              <h4 class="fw-bold">QCL Executive Board !!!!!</h4>
              <p class="leads">Oooh. These are the big, scary bosses. They don't have sandwiches for lunch like the rest of us, they eat raw quail eggs and caviar. They don't drink beer, they quaff Chateau Rothschild 1996. If they went to the toilet - which they don't, 'cos they're royal - they'd use £50 notes instead of Andrex.</p>
              <p class="leads">If you want to spot them when they're out and about, just look for the teams of personal servants, specifically employed to scatter rose petals in front of them wherever they go (a word of warning, though: this can get a bit embarrasing if you ever have to share a tube carriage with one of them).</p>

              <h4 class="fw-bold">QCL Commercial & Strategy</h4>
              <p class="leads">Not only do these clever souls get hold of all those lovely new games, hardware and DVDs that we sell, they are also responsible for making sure we stay the cheapest, most value-laden place to buy your stuff. Hurrah for them, then.</p>

              <h4 class="fw-bold">
                Customer Service</h4>
              <p class="leads">These courteous individuals handle all customer enquiries to CeX. From Ballymena to Wolverhampton, they will deal with all your questions; Sales, Trade-ins, the whole lot. [Go on, give us a shout.](/site/support).</p>

              <h4 class="fw-bold">E-Commerce</h4>
              <p class="leads">Don't live near CeX, too lazy to travel to one of our stores? No problem! [Get in touch](/site/support), let them do the dirty work for you.</p>

              <h4 class="fw-bold">QCL Accounts</h4>
              <p class="leads">These are the people who hand out our wages, so they are officially the Best People In The World Ever. They also make lots of risque jokes about double-entry... Accountants find that sort of thing funny, apparently.</p>

              <h4 class="fw-bold">QCL HR</h4>
              <p class="leads">Fancy working for the funkiest videogames and PC retailer in the whole wide world? These are the people to talk to.</p>

              <h4 class="fw-bold">QCL IT</h4>
              <p class="leads">
                Made up from a team of highly evolved beings, the CeX IT team is capable of breaking - and then fixing - computers in ways that mere mortal minds can't even comprehend. If that fails, there's always turning it off and back on again.</p>

              <h4 class="fw-bold">QCL Prevention</h4>
              <p class="leads">This is our superhero team of health and safety checkers and crime fighters. If anyone is ever daft enough to try and pilfer something from our stores, it'll be CeX Prevention's hands on their shoulder. The would-be criminal will then be subjected to a five-hour lecture on the importance of workplace safety. So, remember kids... crime doesn't pay!</p>
            </div>

          </div>
          {/* <!-- ----------------------------PressClippings---------------------------- --> */}
          <div id="PressClippings" class="page">
            <h1 style={{ color: "#FFFFFF" }}>Press Clippings</h1>
            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="/image/press clipping/1.png" class="img-fluid rounded-start p-3" style={{ width: "500px" }} alt="..." />
                </div>

                <div class="col-md-8">
                  <div class="card-body ">
                    <h5 class="card-title pt-5 font-weight-bold text-white">QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon</h5>
                    <p class="card-text mi-3 text-white">Friday, 7 Jul 2023</p>
                    <p class="card-text text-white">.‣ QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon ...</p>
                    <button type="button" class="btn  rounded-pill " style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="/image/press clipping/2.png" class="img-fluid rounded-start p-3" style={{ width: "500px" }} alt="..." />
                </div>

                <div class="col-md-8">
                  <div class="card-body ">
                    <h5 class="card-title pt-5 font-weight-bold text-white">QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon</h5>
                    <p class="card-text mi-3 text-white">Friday, 7 Jul 2023</p>
                    <p class="card-text text-white">.‣ QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon ...</p>
                    <button type="button" class="btn  rounded-pill " style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="/image/press clipping/3.png" class="img-fluid rounded-start p-3" style={{ width: "500px" }} alt="..." />
                </div>

                <div class="col-md-8">
                  <div class="card-body ">
                    <h5 class="card-title pt-5 font-weight-bold text-white">QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon</h5>
                    <p class="card-text mi-3 text-white">Friday, 7 Jul 2023</p>
                    <p class="card-text text-white">.‣ QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon ...</p>
                    <button type="button" class="btn  rounded-pill " style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="/image/press clipping/4.png" class="img-fluid rounded-start p-3" style={{ width: "500px" }} alt="..." />
                </div>

                <div class="col-md-8">
                  <div class="card-body ">
                    <h5 class="card-title pt-5 font-weight-bold text-white">QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon</h5>
                    <p class="card-text mi-3 text-white">Friday, 7 Jul 2023</p>
                    <p class="card-text text-white">.‣ QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon ...</p>
                    <button type="button" class="btn  rounded-pill" style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-md-8 text-center">
                  <button type="button" class="btn btn-lg btn-primary position-relative font-weight-bold  p-2 mb-3 ml-5 " style={{ maxWidth: "1000px", background: "#FFFFFF", color: "#071A44" }}>
                    Older Posts
                    <span class="d-none d-md-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>





          </div>
          {/* <!-- -----------------------------AboutPaul------------------------------ --> */}
          <div id="AboutPaul" class="page">
            <h1 style={{ color: "#FFFFFF" }}>About Paul</h1>
            <p class="paragraph ">Paul Farrington, an experienced diver and one of the Computer Exchange's founding directors, was listed as missing after exploring an underwater wreck near Mullion Island, Cornwall on Monday (1 May 2000).</p>
            <p class="paragraph ">Paul imbued the Computer Exchange with his spirit. At its inception in 1992 he played a central role in creating its philosophy of free trade in goods for all. His enlightened understanding of human nature and personal warmth informed us all. He will be sorely missed by his many friends at the Computer Exchange.</p>
            <p class="paragraph">Paul's extraordinary career and farsighted approach to technology and the market place helped us develop our unique way of doing business. As a music journalist, technological pioneer and diver, Paul approached everything with a profound enthusiasm.</p>
            <p class="paragraph">The Computer Exchange is with his family and many friends in this hour, and offers them all the support at our disposal.</p>
            <p class="paragraph">It has come to our attention that some of the press reports regarding Paul have been inaccurate. To clarify issues;</p>
            <ul class="ml-3" style={{ color: "#FFFFFF" }}>
              <li>Paul Farrington is English and was born on 15/2/61.</li>
              <li>Paul Farrington was a founding Director of Computer Exchange with Robert Dudani</li>
              <li>Paul Farrington was highly regarded within both the computer and the video game industries</li>
              <li>He was not married and has no children</li>
              <li>He gained a Degree in Philosophy</li>
              <li>Paul Farrington lived in Camden Town, north London</li>
            </ul>
            <p class="paragraph">Many thanks to NTK, the weekly IT newsletter, for the following tribute to Paul</p>
            <p class="paragraph">Paul Farrington, co-founder of the CEX, the Computer Exchange in Tottenham Court Road, disappeared while exploring an underwater wreck off the coast of Cornwall last Tuesday. Anyone who has spent any time at CEX (and especially those who spent the bulk of their teenage years hanging out there) might have guessed that its inspiration came from more than the usual flogging of dodgy second-hand kit</p>
            <p class="paragraph">The compulsory smoking rules, the bizarrely knowledgeable staff, the eclectic (and tympanum-crunchingly loud) musical choices: Judging from the messages of shock and love we've heard, Paul was a great and unique part of the CEX ambience. "He was a surrogate father to a generation of geeks", said one correspondent. Charlie Brooker writes:</p>
            <p class="paragraph">One of my enduring memories of Paul is the day I first met him; I'd turned up for my first day at work in the computer department of Music and Video Exchange in Notting Hill, and walked in to find nobody there. I sat behind the counter for about half an hour, not really knowing what to do, and was about to leave when a tall, gangly man wearing pink tights and a fcking fez* stalked in, looked confused for a while, and then asked me who I was and what I was doing there. I really wasn't sure whether to run away or not. I'm glad I didn't. Once you got used to the fact he dressed like a maniac (and I particularly remember an incredible haircut he once had which made him look like a cross between Coolio and an exploding picnic basket), he became an incredibly likeable, inventive, anarchic, and weirdly trustworthy companion. I always looked up to him, even when he convinced me to shave all my hair off apart from one off-centre tuft, and I spent an entire month watching strangers cross the street when they saw me coming.</p>
            <p class="paragraph">Probably the simplest thing I could say about him is this: that he was easily the coolest person I've ever met, and my head refuses to believe he's gone.</p>



          </div>
          {/* <!-- ----------------------------------Cexlogos----------------------- --> */}
          <div id="CeXLogos" class="page">
            <h1 style={{ color: "#FFFFFF" }}>QCL Logos</h1>
            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-12 ml-5 ml-5-sm">
                  <h6 class="card-title font-weight-bold pt-2 text-white">QCL Logo Rich black CMYK-01.png</h6>
                  <div class="d-flex align-items-center">
                    <img src="/image/logo.png" class="img-fluid rounded-start" style={{ width: "200px" }} alt="..." />
                    <div class="d-flex kl" style={{ background: "#071A44", color: "#FFFFFF" }}>
                      <button type="button" class="btn btn-lg me-2 rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="button" class="btn btn-lg rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Download</button>
                    </div>
                  </div>
                  <p class="card-text pt-2 text-white">For print CMYK Rich blacks- unsuitable for newsprint</p>
                </div>
              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-12 ml-5">
                  <h6 class="card-title font-weight-bold pt-2 text-white">QCL Logo Rich black CMYK-01.png</h6>
                  <div class="d-flex align-items-center">
                    <img src="/image/logo.png" class="img-fluid rounded-start" style={{ width: "200px" }} alt="..." />
                    <div class="d-flex kl" style={{ background: "#071A44", color: "#FFFFFF" }}>
                      <button type="button" class="btn btn-lg me-2 rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="button" class="btn btn-lg rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Download</button>
                    </div>


                  </div>
                  <p class="card-text pt-2 text-white">For print CMYK Rich blacks- unsuitable for newsprin</p>
                </div>


              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-12 ml-5">
                  <h6 class="card-title font-weight-bold pt-2 text-white">QCL Logo Rich black CMYK-01.png</h6>
                  <div class="d-flex align-items-center">
                    <img src="/image/logo.png" class="img-fluid rounded-start" style={{ width: "200px" }} alt="..." />
                    <div class="d-flex kl" style={{ background: "#071A44", color: "#FFFFFF" }}>
                      <button type="button" class="btn btn-lg me-2 rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="button" class="btn btn-lg rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Download</button>
                    </div>


                  </div>
                  <p class="card-text pt-2 text-white">For print CMYK Rich blacks- unsuitable for newsprin</p>
                </div>


              </div>
            </div>

          </div>
          {/* <!-- -------------------------GenderPayGap----------------- --> */}
          <div id="GenderPayGap" class="page" style={{ color: "#FFFFFF" }}>
            <h1 >Gender Pay Gap</h1>
            <p >The reports below sets out our findings and provides some context to explain QCL gender pay gap figures.</p>
            <p>Download our Gender Pay Gap report 2022 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2021 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2020 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2019 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2018 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2017 here (pdf)</p>
          </div>
          {/* <!-- ----------------------ModernSlaveryStatement--------------------- --> */}
          <div id="ModernSlaveryStatement" class="page" style={{ color: "#FFFFFF" }}>
            <h1>Modern Slavery Statement</h1>
            <h4>Introduction</h4>
            <p>This statement is made pursuant to section 54 of the Modern Slavery Act 2015. It constitutes QCL slavery and human trafficking statement for the financial year ended 31 December 2018.</p>
            <h4>Our Organization</h4>
            <p>QCL was founded in London in 1992. We have stores in the UK, Spain, Ireland, India, Australia, Portugal, Netherlands, Mexico, Poland and Canarias.</p>
            <p>We buy, sell and exchange a range of technology and entertainment products including mobile phones, video games, DVDs and Blu-ray movies, computers, digital electronics, TVs and monitors, and music CDs.</p>
            <p>We have more than 700 stores worldwide and we are growing.</p>
            <h4>
              Our Supply Chain</h4>
            <p>As a second hand trade company our supply chain is the customer. We do however work with third party companies who act as a support service to our needs.</p>
            <h4>Policies</h4>
            <p>https://dev-portal.cex.webuy.dev/transfer-group-managerAs an expanding business, we are in the process of reviewing our policies in order to make sure that our third parties understand our expectations with regards to human rights across all areas of our supply chain.</p>
            <p>QCL actively encourages employees to report any activity that they believe is in breach of company human rights standards, for this we have a whistleblowing procedure in place to report any concerns about the way we and our third parties perform our tasks.</p>
            <h4>Due Diligence and Risk Assessment</h4>
            <p>QCL is fortunate that out supply is from the general public, but we still use support services to assist with the day to day running of our business. Many of these parties already have robust Due Diligence measures in place.</p>
            <p>As part of the ongoing improvement to QCL policies and standards, we are ensuring that we have a more comprehensive approach to risk assessment and identification of risk. In 2018 we made substantial improvements to our compliance department to assist in reviewing policies and procedures belonging to ourselves and to our third parties.</p>
            <p>Where applicable we visit our third parties to ensure their working conditions, policies and procedures are up to an excellent standard. Given the geographical location of some of our third parties, we are committed to understanding our relationships better by ensuring transparency with strong working contracts.</p>
            <h4>Measurement of Effectiveness</h4>
            <p>CEX will continue to monitor our third party support services both existing and new. Should any instances of non-compliance with the Act arise in relation to any of our third parties then this will be reviewed.</p>

          </div>
          {/* <!-- ---------------------------------19Response-------------------------- --> */}
          <div id="OurCOVID-19Response" class="page" style={{ color: "#FFFFFF" }}>
            <h1>How QCL is responding to coronavirus (COVID-19)</h1>
            <p>As we look to open our stores again to the public we have composed this page to give you an idea of what has changed to ensure we follow government guidelines.</p>
            <h4>What to expect in our stores to keep you and our staff safe.
              Social distancing</h4>
            <p>During our busier times we may limit the amount of customers entering the store, where possible there will be queue guidance outside the store. To help you maintain a distance of two metres from other customers and staff, you'll find floor markings inside our stores to advise social distancing. We've also put up Perspex protector screens at our tills areas to keep our dedicated staff safe.</p>
            <p>PPE has also been stocked efficiently to ensure that safety options are available.</p>
            <p>We have also made careful alterations to our stores who operate with the option of using MePOS devices (used for Mobile customer engagement on the shop floor). All stores that have both MePOS and a Till system available will revert to only a Till system being used to assist with the safety of our customers and staff</p>
            <h4>Contactless payments & Vouchers</h4>
            <p>We are encouraging you to use contactless card payment where possible. The nationwide limit on contactless card payments has increased from £30 to £45 to reduce physical contact at checkout. We are of course fully honouring any vouchers you have in your possession.</p>
            <h4>Demoing of an item you wish to buy</h4>
            <p>Due to social distancing and the necessary changes to have both your safety and our staff's safety in mind, our staff will provide the demos behind the counter and will offer you the opportunity to inspect only once you have purchased. We apologise for the inconvenience this may cause but we hope this does not discourage you from purchasing your goods.</p>
            <h4>Trading in your goods consideration</h4>
            <p>We are still accepting your goods for cash or vouchers. What we ask in return is to be considerate in the cleanliness of the item when bringing into store. Where possible, sanitize your items and place in a bag so our staff can take out without any further interaction from yourself. We will, of course, clean the product before placing the item back out for sale.</p>
            <p>Where at all possible, we encourage you to accept a bank transfer for any exchange for cash. Vouchers will of course still be offered.</p>
            <h4>Changes to store operating hours</h4>
            <p>In these unprecedented times, our stores may change their opening hours as they see necessary. We are working hard to reflect these changes as quickly as possible on our store locator but please be aware, information may not be accurate.</p>
            <p>If you are feeling unwell and have any of the COVID-19 symptoms</p>
          </div>
          {/* <!-- ---------------------------------------CounterfeitProductsPolicy------------------- --> */}
          <div id="CounterfeitProductsPolicy" class="page" style={{ color: "#FFFFFF" }}>
            <h1>Counterfeit Products Policy</h1>
            <p>We do not buy or sell counterfeit products in CeX in stores or via webuy.com.</p>
            <h4 class="pt-3">What is the policy?</h4>
            <p>Counterfeit products are illegal and not allowed on webuy.com or in CeX stores.</p>
            <h5>Examples include:</h5>
            <ul class="ml-3">
              <li>Electronics or accessories that use or bear the brand name, logo, or trademark of a company that didn't manufacture the item.</li>
              <li>Pirated or fake copies of movies, games or other software.</li>
            </ul>
            <p>Activity that doesn't follow CeX’s policy in regards to this may result in the closure of your account in addition to other actions.</p>
            <h4>Why does CeX have this policy?</h4>
            <p>To prevent the sale of counterfeit products, which are illegal and may impact on future customers</p>
          </div>
          {/* <!-- ---------------------------------Sell Games & Consoles--------------------- --> */}
          <div id="about" class="page">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>Sell Games & Consoles</h1>
              <input type="checkbox" />
              <img src="/image/main.png" alt="" className="imagesize" />
            </div>
            <h1 class="pt-3" style={{ color: "#FFFFFF" }}>Sell Games & Consoles for CASH</h1>
            <h6 style={{ color: "#FFFFFF" }}>Don’t let your unwanted consoles gather dust. Get top cash for them in-store or online today! Click the buttons below to see how much you could get</h6>
            <br /><br />
            <div class="card-deck wimage">
              <div class="card" >
                <img class="card-img-top p-5" src="/image/sell game/1.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center rounded p-3 text-white" >Switch</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/sell game/2.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center rounded p-3 text-white" >Xbox Series X</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/sell game/3.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center rounded p-3 text-white" >Xbox Series S</h5>
                  {/* <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> --> */}
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/sell game/8.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center rounded p-3 text-white" >Wii</h5>
                </div>

              </div>

            </div>
            <br /><br />
            {/* </div><br/><br/>  */}
            {/* <!-- --------------------------------second card----------------------- --> */}
            <div class="card-deck wimage">
              <div class="card" >
                <img class="card-img-top p-5" src="/image/sell game/1.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center rounded p-3 text-white" >Switch</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/sell game/6.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center rounded p-3 text-white" >Xbox Series X</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/sell game/7.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center rounded p-3 text-white" >Xbox Series S</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/sell game/8.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center rounded p-3 text-white" >Wii</h5>
                </div>

              </div>

            </div><br /><br />
            <div>
              <button type="button" class="btn btn-lg p-3 ml-4 pt-2 w-25 w-md-25" style={{ background: "#FFFFFF", color: "#071A44" }}>All other Game</button>
            </div>
          </div>

        </div>


        <br /><br />
        <div class="row justify-content-center mb-5">
         
        </div>
      </section>
      <Footer/>
    </>
  )
}
export default Aboutus;