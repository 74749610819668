import { useEffect, useState } from "react";
import './slider.css';
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper"
import { Link } from "react-router-dom";

const Slider = () => {

    const [getData, setGetData] = useState([])
    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        getAllAdminProduct();
    }, []);
    const getAllAdminProduct = () => {
        axios.get(`${Env.server}/api/product/getAllProductOfAdmin`)
            .then((res) => {
                console.log("abc--===>>>", res.data.message);
                console.log("abc--===>>>", res.data.products);
                setGetData(res.data.products)
            })
            .catch((err) => {
                console.log("err===", err)
            })
    }
    const handleNext = () => {
        if (startIndex + 4 < getData.length) {
            setStartIndex(startIndex + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
        }
    };

  return (
        <>
            <h1>
                <section style={{ background: "#FFFFFF" }}>

                    <h1 class="text-center my-5" style={{color: "#071A44" }}>Choose from most selling itmes</h1>
                    <div class="container-fluid carousel-dark" data-bs-ride="carousel">
                        <div class="row justify-content-evenly">
                        {getData.slice(startIndex, startIndex + 4).map((data, index) => (
                                <div class="kh col-lg-3" >
                                    <Link to={`/Slidercontent/${data._id}`} class="co">
                                        <img key={data._id} src={getAsset(data.image[0])} class="img-fluid p-3" style={{ width: "100%", height: "30vh" }} />
                                        <p class=" px-2">{data.item}</p>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="left">
                                                <p class="card-text p-2 text-white" style={{ fontSize: "1rem" }}> {data.name} </p>
                                                <p class="card-text p-2 text-white" style={{ fontSize: "1rem" }}>CASH <br /> £{data.price} <br /></p>
                                            </div>
                                            <div class="circle-container">
                                                <div class="circle" style={{width:"50px"}}>
                                                    <img src="../image/banarimage/icon.png" style={{ width: "30px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div class="section pt-3" style={{ background: "#FFFFFF", height: "15vh" }}>
                            <div class="carousel-controls" style={{ marginLeft: "-25rem", marginRight: "10rem"}}>
                                <button class="carousel-control-prev rounded-pill " type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{color: "#071A44", border: "2px solid #071A44", width: "6%" }} onClick={handlePrev}>
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden mr-5">Previous</span>
                                </button>

                                <button class="carousel-control-next rounded-pill " type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" style={{color: "#071A44", border: "2px solid #071A44", width: "6%"  }} onClick={handleNext}>
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>



                </section>
            </h1>
        </>
    )
}
export default Slider;