import React from "react";
import "./Footer.css"
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="bg-white" >
        <footer class="bg-body-tertiary  bg-black   container text-lg-start" style={{ backgroundColor: "black !important" }} >
          <div class="row bg-white">
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0 pt-4" >
              <h5 class="text-uppercase">About</h5>
              <ul class="list-unstyled mb-0" >
                <li>
                  <Link to="/Aboutus" className="coler" > About Us  </Link>
                </li>
                <li>
                  <a href="#!" className="coler" >Sell an item</a>
                </li>
                <li>
                  <a href="#!" className="coler">Careers</a>
                </li>
                <li>
                  <a href="#!" className="coler">Franchising</a>
                </li>
                <li>
                  <a href="#!" className="coler">CeX Blog</a>
                </li>
                <li>
                  <a href="#!" className="coler">Press</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0 pt-4 ">
              <a href="/supportslider.html" class="about-linkk">
                <h5 class="text-uppercase mb-0" className="coler">Support</h5>
              </a>
              <ul class="list-unstyled">
                <li>
                  <a href="#!" className="coler">Find a store</a>
                </li>
                <li>
                  <Link to="/support" href="#!" className="coler">Contact Us</Link>
                </li>
                <li>
                  <a href="#!" className="coler">FAQ</a>
                </li>

              </ul>
            </div>
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0 pt-4">
              <h5 class="text-uppercase">Legal</h5>

              <ul class="list-unstyled mb-0 stl" >
                <li>
                  <a href="#!" className="coler">Terms and Conditions</a>
                </li>
                <li>
                  <a href="#!" className="coler">Cookies Policy</a>
                </li>
                <li>
                  <a href="#!" className="coler">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
              <ul class="list-inline social-icons">
                <li class="list-inline-item">
                  <a href="#!" className="coler">
                    <img src="https://png.pngtree.com/png-clipart/20230401/original/pngtree-three-dimensional-instagram-icon-png-image_9015419.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>

                </li>
                <li class="list-inline-item">
                  <a href="#!" className="coler">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/2021_Facebook_icon.svg/1024px-2021_Facebook_icon.svg.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#!" className="coler">
                    <img src="https://cdn-icons-png.flaticon.com/512/4138/4138134.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#!" class="text-body">
                    <img src="https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_twitter-512.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>
                </li>
              </ul>
            </div>

          </div>
          <div class="text-center p-3 bg-white">
            Designed by Website Access
            <a class="text-black" href="https://mdbootstrap.com/" >:  All rights reserved Quick Cash Leicester 2023. ©</a>
          </div>
        </footer>
        <div class="row justify-content-center ">
          <div class="col-md-2 pb-4">
            <img class="img-fluid " src="../image/banarimage/icon-removebg-preview.png" alt="..." />
          </div>
        </div>
      </div >
    </>
  )
}
export default Footer;
